// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-clients-index-js": () => import("./../src/pages/clients/index.js" /* webpackChunkName: "component---src-pages-clients-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-demo-index-js": () => import("./../src/pages/demo/index.js" /* webpackChunkName: "component---src-pages-demo-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-features-index-js": () => import("./../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-jobs-apply-now-js": () => import("./../src/pages/jobs/apply-now.js" /* webpackChunkName: "component---src-pages-jobs-apply-now-js" */),
  "component---src-pages-jobs-front-end-lead-js": () => import("./../src/pages/jobs/front-end-lead.js" /* webpackChunkName: "component---src-pages-jobs-front-end-lead-js" */),
  "component---src-pages-jobs-index-js": () => import("./../src/pages/jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-pages-jobs-lead-developer-js": () => import("./../src/pages/jobs/lead-developer.js" /* webpackChunkName: "component---src-pages-jobs-lead-developer-js" */),
  "component---src-pages-jobs-thanks-js": () => import("./../src/pages/jobs/thanks.js" /* webpackChunkName: "component---src-pages-jobs-thanks-js" */),
  "component---src-pages-log-in-index-js": () => import("./../src/pages/log-in/index.js" /* webpackChunkName: "component---src-pages-log-in-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-product-ecommerce-solution-js": () => import("./../src/pages/product/ecommerce-solution.js" /* webpackChunkName: "component---src-pages-product-ecommerce-solution-js" */),
  "component---src-pages-product-index-js": () => import("./../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-product-resellers-ota-js": () => import("./../src/pages/product/resellers-ota.js" /* webpackChunkName: "component---src-pages-product-resellers-ota-js" */),
  "component---src-pages-product-tour-operator-supplier-js": () => import("./../src/pages/product/tour-operator-supplier.js" /* webpackChunkName: "component---src-pages-product-tour-operator-supplier-js" */),
  "component---src-pages-product-website-builder-cms-js": () => import("./../src/pages/product/website-builder-cms.js" /* webpackChunkName: "component---src-pages-product-website-builder-cms-js" */),
  "component---src-pages-services-bokun-consultation-js": () => import("./../src/pages/services/bokun-consultation.js" /* webpackChunkName: "component---src-pages-services-bokun-consultation-js" */),
  "component---src-pages-services-custom-development-js": () => import("./../src/pages/services/custom-development.js" /* webpackChunkName: "component---src-pages-services-custom-development-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-setup-index-js": () => import("./../src/pages/setup/index.js" /* webpackChunkName: "component---src-pages-setup-index-js" */),
  "component---src-pages-sign-up-index-js": () => import("./../src/pages/sign-up/index.js" /* webpackChunkName: "component---src-pages-sign-up-index-js" */),
  "component---src-pages-sign-up-thanks-js": () => import("./../src/pages/sign-up/thanks.js" /* webpackChunkName: "component---src-pages-sign-up-thanks-js" */),
  "component---src-pages-team-index-js": () => import("./../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */)
}

